import React from 'react';

import StatusSuccess from './success';

import RaisedCard from 'components/raised-card';
import DeviceInfo from 'components/device-info';

export class SuccessPage extends React.Component {
  render() {
    const { status, imageSrc } = this.props;

    return (
      <React.Fragment>
        <StatusSuccess
          lastConnection={status.lastHeartBeatTime}
          isp={status.ispName}
        />
        <RaisedCard>
          <DeviceInfo
            name={status.hardwareName}
            version={status.osVersion}
            imageSrc={imageSrc}
            serial={status.serialNumber}
          />
        </RaisedCard>
      </React.Fragment>
    );
  }
}
