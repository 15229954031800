import React from 'react';

import { injectIntl } from 'gatsby-plugin-intl';
import moment from 'moment';

import errorIcon from '../../../content/images/times-circle-regular.svg';
import LabelValue from 'components/label-value';

class StatusErrorComp extends React.Component {
  render() {
    const { lastConnection, isp, disconnected, intl } = this.props;

    moment().locale('ja');

    let timeString = '';

    if (disconnected) {
      const timezonedLastConnection = moment
        .utc(lastConnection)
        .local()
        .format('LL LTS');
      const timezoneAbbrev = moment()
        .tz(moment.tz.guess())
        .format('z');

      timeString = `${timezonedLastConnection} ${timezoneAbbrev}`;
    }

    return (
      <div className="container status status-error light-background">
        <div className="status-info">
          <img className="status-icon error" src={errorIcon} />
          <span className="status-text">
            {intl.formatMessage({ id: 'status.error.status_text' })}
          </span>
        </div>
        {disconnected && (
          <div className="status-device-info">
            <LabelValue label={'Last connection'} value={timeString} />
            <LabelValue label={'ISP'} value={isp} />
          </div>
        )}
      </div>
    );
  }
}

export const StatusError = injectIntl(StatusErrorComp);

export default injectIntl(StatusErrorComp);
