import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import moment from 'moment';

import { siteMetadata } from '../../gatsby-config';
import Layout from 'components/layout';

import {
  SuccessPage,
  ErrorRetryPage,
  ErrorNoConnectionPage,
} from 'components/status';
import LoadingSpinner from 'components/loading-spinner';

const DISCONNECT_DETERMINATION_TIME = 10; // amount of minutes determining the device is in "disconnected" mode
moment().locale('ja');

const StatusStepTypes = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR_RETRY: 'error_retry',
  ERROR_NO_CONNECTION: 'error_no_connection',
  DISCONNECTED: 'error_disconnected',
};

const isDisconnected = lastConnection => {
  const now = moment();
  const last = moment(lastConnection);
  const lastShifted = moment(last).add(
    DISCONNECT_DETERMINATION_TIME,
    'minutes'
  );

  return moment(lastShifted).isBefore(now);
};

const fetchStatus = async (serialNumber, currentStep) => {
  return new Promise((resolve, reject) => {
    fetch(`${siteMetadata.statusURL}?serial_number=${serialNumber}`)
      .then(response => response.json())
      .then(response => {
        if (isDisconnected(response.lastHeartBeatTime)) {
          const nextStep =
            currentStep === StatusStepTypes.LOADING
              ? StatusStepTypes.DISCONNECTED
              : StatusStepTypes.ERROR_NO_CONNECTION;

          resolve({
            response,
            nextStep,
          });

          return;
        }

        resolve({
          response,
          nextStep: StatusStepTypes.SUCCESS,
        });
      })
      .catch(error => {
        reject({
          error,
          nextStep:
            currentStep === StatusStepTypes.LOADING
              ? StatusStepTypes.ERROR_RETRY
              : StatusStepTypes.ERROR_NO_CONNECTION,
        });
      });
  });
};

class Status extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: {},
      step: StatusStepTypes.LOADING,
    };
  }

  async componentDidMount() {
    try {
      const { location } = this.props;
      const serialNumber =
        (location && location.state && location.state.serialNumber) || '';

      const statusObj = await fetchStatus(serialNumber, this.state.step);

      this.setState({
        status: statusObj.response,
        step: statusObj.nextStep,
      });
    } catch (errorObj) {
      console.log('fetching status error', errorObj);

      this.setState({
        error: errorObj.error,
        step: errorObj.nextStep,
      });
    }
  }

  handleRetryButtonClick = serialNumber => {
    this.setState(
      {
        step: StatusStepTypes.LOADING,
      },
      async () => {
        try {
          const statusObj = await fetchStatus(
            serialNumber,
            StatusStepTypes.ERROR_RETRY
          );

          this.setState({
            status: statusObj.response,
            step: statusObj.nextStep,
          });
        } catch (errorObj) {
          console.log('retrying fetch status error', errorObj);

          this.setState({
            error: errorObj.error,
            step: errorObj.nextStep,
          });
        }
      }
    );
  };

  render() {
    const { data, location } = this.props;
    const { status, step } = this.state;
    const serialNumber =
      (location && location.state && location.state.serialNumber) || '';
    const deviceImageSrc = get(data, 'pi4.childImageSharp.fixed.src');
    const scanImage = get(data, 'scan.childImageSharp.fixed.src');

    return (
      <Layout>
        {step === StatusStepTypes.LOADING && <LoadingSpinner />}
        {step === StatusStepTypes.SUCCESS && (
          <SuccessPage status={status} imageSrc={deviceImageSrc} />
        )}
        {(step === StatusStepTypes.DISCONNECTED ||
          step === StatusStepTypes.ERROR_RETRY) && (
          <ErrorRetryPage
            onRetryButtonClick={this.handleRetryButtonClick}
            currentSerialNumber={serialNumber}
            status={status}
            imageSrc={deviceImageSrc}
            disconnected={step === StatusStepTypes.DISCONNECTED}
            scanImg={scanImage}
          />
        )}
        {step === StatusStepTypes.ERROR_NO_CONNECTION && (
          <ErrorNoConnectionPage />
        )}
      </Layout>
    );
  }
}

export default Status;

export const pageQuery = graphql`
  query StatusQuery {
    pi4: file(name: { eq: "pi4_sized" }) {
      childImageSharp {
        fixed(width: 500, height: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    scan: file(name: { eq: "scan_image" }) {
      childImageSharp {
        fixed(width: 466, height: 181) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
