import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { injectIntl } from 'gatsby-plugin-intl';

import LabelValue from 'components/label-value';

import successIcon from '../../../content/images/check-circle-regular.svg';

export class StatusSuccessComp extends React.Component {
  render() {
    const { lastConnection, isp, intl } = this.props;

    moment().locale('ja');

    const timezonedLastConnection = moment(lastConnection)
      .local()
      .format('LL LTS');
    const timezoneAbbrev = moment()
      .tz(moment.tz.guess())
      .format('z');
    const timeString = `${timezonedLastConnection} ${timezoneAbbrev}`;

    return (
      <div className="container status status-success light-background">
        <div className="status-info">
          <img className="status-icon" src={successIcon} />
          <span className="status-text">
            {intl.formatMessage({ id: 'status.success.status_text' })}
          </span>
        </div>
        <div className="status-device-info">
          <LabelValue label={'Last connection'} value={timeString} />
          <LabelValue label={'ISP'} value={isp} />
        </div>
      </div>
    );
  }
}

export const StatusSuccess = injectIntl(StatusSuccessComp);

export default injectIntl(StatusSuccessComp);
