import React from 'react';

import { injectIntl } from 'gatsby-plugin-intl';

import StatusError from 'components/status/error';

export class ErrorNoConnectionPageComp extends React.Component {
  render() {
    const { intl } = this.props;

    return (
      <React.Fragment>
        <StatusError />

        <h1 className="error-header">
          {intl.formatMessage({ id: 'status.error_no_connection_page.header' })}
        </h1>

        <p>
          {intl.formatMessage({
            id: 'status.error_no_connection_page.message.first_part',
          })}
          <a href="https://forms.gle/YEtZmVLCDJsHwcDD6" target="_blank">
            {intl.formatMessage({
              id: 'status.error_no_connection_page.message.second_part',
            })}
          </a>
          {intl.formatMessage({
            id: 'status.error_no_connection_page.message.third_part',
          })}
        </p>
      </React.Fragment>
    );
  }
}

export const ErrorNoConnectionPage = injectIntl(ErrorNoConnectionPageComp);

export default injectIntl(ErrorNoConnectionPageComp);
