import React from 'react';

import { injectIntl } from 'gatsby-plugin-intl';

import LabelValue from 'components/label-value';

export const DeviceInfoComp = ({ imageSrc, name, serial, version, intl }) => (
  <div className="device-info">
    <div className="device-image">
      <img src={imageSrc} />
    </div>
    <div className="device-details">
      <LabelValue
        label={intl.formatMessage({ id: 'device_info.name' })}
        value={name}
      />
      <LabelValue
        label={intl.formatMessage({ id: 'device_info.serial_number' })}
        value={serial}
      />
      <LabelValue
        label={intl.formatMessage({ id: 'device_info.version' })}
        value={version}
      />
    </div>
  </div>
);

export const DeviceInfo = injectIntl(DeviceInfoComp);

export default injectIntl(DeviceInfoComp);
