import React from 'react';

import { injectIntl } from 'gatsby-plugin-intl';

import { checkCameraInputEffect } from '../../utils/hooks';

import Instructions from 'components/instructions';
import SerialNumber from 'components/serial-number';
import StatusError from 'components/status/error';
import RaisedCard from 'components/raised-card';
import DeviceInfo from 'components/device-info';

import ScanLayout from 'components/scanning/scan-layout';

const retrieveInstructionFromTranslation = (translations, translationKey) => {
  return {
    title: translations.formatMessage({ id: `${translationKey}.title` }),
    desc: translations.formatMessage({ id: `${translationKey}.description` }),
  };
};

export class ErrorRetryPageComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showStartScanningModal: false,
      cameraInputAvailable: null,
    };
  }

  async componentDidMount() {
    try {
      await checkCameraInputEffect(this.setCameraInputAvailable);
    } catch (error) {}
  }

  setShowScanningModal = value => {
    this.setState({
      showStartScanningModal: value,
    });
  };

  setCameraInputAvailable = value => {
    this.setState({
      cameraInputAvailable: value,
    });
  };

  handleScanButtonClick = () => {
    this.setShowScanningModal(true);
  };

  handleScanCheckButtonClick = serialNumber => {
    this.props.onRetryButtonClick(serialNumber);
  };

  handleTerminateScanFunctionality = () => {
    this.setShowScanningModal(false);
  };

  render() {
    const { status, imageSrc, disconnected, scanImg, intl } = this.props;

    const instructions = [
      retrieveInstructionFromTranslation(
        intl,
        'status.error_retry_page.instructions.01'
      ),
      retrieveInstructionFromTranslation(
        intl,
        'status.error_retry_page.instructions.02'
      ),
      retrieveInstructionFromTranslation(
        intl,
        'status.error_retry_page.instructions.03'
      ),
    ];

    return (
      <React.Fragment>
        <ScanLayout
          scanInstructionImage={scanImg}
          onCheckButtonClick={this.handleScanCheckButtonClick}
          onTerminateScanFunctionality={this.handleTerminateScanFunctionality}
          initializeScanFunctionality={this.state.showStartScanningModal}
        />

        <StatusError
          disconnected={disconnected}
          lastConnection={status.lastHeartBeatTime}
          isp={status.ispName}
        />

        {status && status.serialNumber && (
          <RaisedCard>
            <DeviceInfo
              name={status.hardwareName}
              version={status.osVersion}
              serial={status.serialNumber}
              imageSrc={imageSrc}
            />
          </RaisedCard>
        )}

        <h1 className="error-header">
          {intl.formatMessage({ id: 'status.error_retry_page.header' })}
        </h1>

        <Instructions instructions={instructions} />

        <p className="margin-top">
          {intl.formatMessage({
            id: 'status.error_retry_page.current_serial_number',
          })}
        </p>
        <SerialNumber
          onCheckButtonClick={this.props.onRetryButtonClick}
          currentValue={this.props.currentSerialNumber}
          useCamera={this.state.cameraInputAvailable}
          onScanButtonClick={this.handleScanButtonClick}
        />
      </React.Fragment>
    );
  }
}

export const ErrorRetryPage = injectIntl(ErrorRetryPageComp);

export default injectIntl(ErrorRetryPageComp);
