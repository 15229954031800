import React from 'react';

export const LabelValue = ({ label, value }) => (
  <div className="label-value">
    <div className="label">{label}</div>
    <div className="value">{value}</div>
  </div>
);

export default LabelValue;
